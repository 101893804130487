<template>
  <div class="accordion">
    <template v-for="panel in panels" :key="panel">

      <div class="accordion-panel" :class="{
        'active': panel.key === activePanelKey
      }">
        <button class="accordion-panel-toggle"
          type="button"
          @click="activePanelKey = panel.key"
        >
          <h3 class="accordion-panel-title">{{ panel.title }}</h3>
          <SvgIcon class="accordion-panel-toggle-icon" name="caret-down" />
        </button>

        <collapse-transition>
          <div class="accordion-panel-content" v-show="panel.key === activePanelKey">
            <slot :name="panel.key"></slot>
          </div>
        </collapse-transition>
      </div>

    </template>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: 'Accordion',

  components: {
    SvgIcon,
    CollapseTransition,
  },

  props: {
    panels: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      activePanelKey: null,
    }
  },

  mounted() {
    // First panel is active by default
    this.activePanelKey = this.panels.at(0).key
  },
}
</script>

<style lang="scss" scoped>
/* ACCORDION */

.accordion {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.accordion-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(4.4rem + 2px);

  &.active {
    flex-grow: 1;
  }
}

.accordion-panel-content {
  flex-grow: 1;
  padding: $gutter-half;
  overflow: auto;
  @include custom-scrollbar($color-gray);
}

.accordion-panel-toggle {
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 100%;
  border: none;
  background-color: lighten($color-protocol-grouping, 7%);
  color: white;
  transition: all 0.125s;

  @include hocus() {
    background-color: $color-protocol-grouping;
  }
}

.accordion-panel-title {
  margin-bottom: 0;
  @include v-padding($gutter-quarter + $gutter-eighth);
  text-transform: uppercase;
}

.accordion-panel-toggle-icon {
  position: absolute;
  right: $gutter-quarter;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s;

  .accordion-panel.active & {
    transform: translateY(-50%) rotateZ(0.5turn);
  }
}
</style>
